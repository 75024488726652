'use client'

import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Card, Divider, Spacer } from '@vinted/web-ui'

import { postAdvertisingLeadForm } from 'data/api'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import FormHeader from './components/FormHeader'
import ContactInformation from './components/ContactInformation'
import CompanyInformation from './components/CompanyInformation'
import AdditionalInformation from './components/AdditionalInformation'
import FormSubmit from './components/FormSubmit'

import { FormModel } from './types'
import FormSuccessModal from './components/FormSuccessModal'
import FormErrorModal from './components/FormErrorModal'

const LeadForm = () => {
  const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false)
  const [shouldShowErrorModal, setShouldShowErrorModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formMethods = useForm<FormModel>({ shouldUnregister: false })

  const { handleSubmit } = formMethods

  async function onSubmit(data: FormModel) {
    setIsLoading(true)

    const response = await postAdvertisingLeadForm(data)

    if ('errors' in response) {
      setShouldShowErrorModal(true)
    } else {
      setShouldShowSuccessModal(true)
    }

    setIsLoading(false)
  }

  function handleCloseSuccessModal() {
    formMethods.reset()
    setShouldShowSuccessModal(false)
  }

  function handleCloseErrorModal() {
    setShouldShowErrorModal(false)
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form className="advertising__form-container" onSubmit={handleSubmit(onSubmit)}>
          <div className="u-desktops-only">
            <Spacer size={Spacer.Size.X5Large} />
          </div>
          <div className="u-mobiles-only">
            <Spacer size={Spacer.Size.X2Large} />
          </div>
          <Card>
            <SeparatedList separator={<Divider />}>
              <FormHeader />
              <ContactInformation />
              <CompanyInformation />
              <AdditionalInformation />
            </SeparatedList>
          </Card>
          <FormSubmit isLoading={isLoading} />
          <div className="u-desktops-only">
            <Spacer size={Spacer.Size.XLarge} />
          </div>
        </form>
      </FormProvider>
      <FormSuccessModal show={shouldShowSuccessModal} handleClose={handleCloseSuccessModal} />
      <FormErrorModal show={shouldShowErrorModal} handleClose={handleCloseErrorModal} />
    </>
  )
}

export default LeadForm
