export enum Field {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phoneNr',
  CompanyType = 'companyType',
  CompanyName = 'companyName',
  EstimatedBudget = 'estimatedBudget',
  TargetCountry = 'targetCountry',
  DealType = 'dealType',
  Comment = 'comment',
}

export const MAX_TEXTAREA_LENGTH = 300
