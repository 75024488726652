import { Screen } from 'constants/tracking/screens'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useSession } from '@marketplace-web/shared/session'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '@marketplace-web/shared/browser'

import { ROKT_ORDER_PRICE_SESSION_KEY, SHOULD_SHOW_ROKT_SESSION_KEY } from '../../../constants'

function useShouldShowRokt() {
  const { screen: screenName } = useSession()
  const isRoktScriptEnabled = useFeatureSwitch('web_ads_rokt_script')
  const shouldUserSeeAds = useAbTest('sqp_ads')?.variant === 'on'

  const isInInbox = [
    Screen.ConversationList,
    Screen.ConversationNew,
    Screen.ConversationReply,
  ].includes(screenName)

  const isRoktAvailable = isRoktScriptEnabled && isInInbox && shouldUserSeeAds

  const shouldShowRokt =
    isRoktAvailable && getSessionStorageItem(SHOULD_SHOW_ROKT_SESSION_KEY) === 'true'
  const roktOrderPrice =
    (isRoktAvailable && getSessionStorageItem(ROKT_ORDER_PRICE_SESSION_KEY)) || '0'

  // 'orderPrice' can be 0 if 'shouldShowRoktValue' is false because all session items will be removed
  function setShouldShowRokt(shouldShowRoktValue: boolean, orderPrice: string = '0') {
    if (shouldShowRoktValue) {
      setSessionStorageItem(SHOULD_SHOW_ROKT_SESSION_KEY, String(shouldShowRoktValue))
      setSessionStorageItem(ROKT_ORDER_PRICE_SESSION_KEY, orderPrice)

      return
    }

    removeSessionStorageItem(SHOULD_SHOW_ROKT_SESSION_KEY)
    removeSessionStorageItem(ROKT_ORDER_PRICE_SESSION_KEY)
  }

  return {
    setShouldShowRokt,
    shouldShowRokt,
    roktOrderPrice,
  }
}

export default useShouldShowRokt
