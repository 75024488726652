import AdvertisingHeroText from './components/AdvertisingHeroText'
import AdvertisingHeroImage from './components/AdvertisingHeroImage'

const Hero = () => {
  return (
    <>
      <div className="advertising__hero">
        <div className="u-desktops-only">
          <div className="container">
            <div className="advertising__hero--container">
              <AdvertisingHeroText />
              <AdvertisingHeroImage />
            </div>
          </div>
        </div>
        <div className="u-mobiles-only">
          <AdvertisingHeroImage isMobile />
        </div>
      </div>
      <div className="u-mobiles-only">
        <AdvertisingHeroText isMobile />
      </div>
    </>
  )
}

export default Hero
