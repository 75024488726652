'use client'

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTranslate } from '@marketplace-web/shared/i18n'

import AdvertisingInputText from '../Inputs/AdvertisingInputText'
import FormAccordion from '../FormAccordion'
import { Field } from '../../constants'
import { FormModel } from '../../types'

const ContactInformation = () => {
  const [isExpanded, setIsExpanded] = useState(true)
  const translate = useTranslate('advertising.form.contact_information')
  const { formState } = useFormContext<FormModel>()

  useEffect(() => {
    if (
      formState.errors[Field.FirstName] ||
      formState.errors[Field.LastName] ||
      formState.errors[Field.Email] ||
      formState.errors[Field.Phone]
    )
      setIsExpanded(true)
  }, [formState])

  return (
    <FormAccordion
      title={translate('title')}
      isExpanded={isExpanded}
      onToggle={setIsExpanded}
      testId="advertising-form-contact-information-accordion"
    >
      <AdvertisingInputText
        name={Field.FirstName}
        title={translate('fields.first_name.title')}
        placeholder={translate('fields.first_name.placeholder')}
        required
      />
      <AdvertisingInputText
        name={Field.LastName}
        title={translate('fields.last_name.title')}
        placeholder={translate('fields.last_name.placeholder')}
        required
      />
      <AdvertisingInputText
        name={Field.Email}
        title={translate('fields.email.title')}
        placeholder={translate('fields.email.placeholder')}
        required
      />
      <AdvertisingInputText
        name={Field.Phone}
        title={translate('fields.phone_nr.title')}
        placeholder={translate('fields.phone_nr.placeholder')}
      />
    </FormAccordion>
  )
}

export default ContactInformation
