'use client'

import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTranslate } from '@marketplace-web/shared/i18n'

import AdvertisingInputText from '../Inputs/AdvertisingInputText'
import AdvertisingSelect from '../Inputs/AdvertisingSelect'
import FormAccordion from '../FormAccordion'
import { Field } from '../../constants'
import { FormModel } from '../../types'

const CompanyInformation = () => {
  const [isExpanded, setIsExpanded] = useState(true)
  const translate = useTranslate('advertising.form.company_information')
  const { formState } = useFormContext<FormModel>()

  const companyTypeData = useMemo(
    () => [
      {
        id: 'Agency',
        title: translate('fields.company_type.options.agency'),
      },
      {
        id: 'Brand',
        title: translate('fields.company_type.options.brand'),
      },
      {
        id: 'Advertiser',
        title: translate('fields.company_type.options.advertiser'),
      },
      {
        id: 'Network',
        title: translate('fields.company_type.options.network'),
      },
    ],
    [translate],
  )

  useEffect(() => {
    if (formState.errors[Field.CompanyType] || formState.errors[Field.CompanyName])
      setIsExpanded(true)
  }, [formState])

  return (
    <FormAccordion
      title={translate('title')}
      isExpanded={isExpanded}
      onToggle={setIsExpanded}
      testId="advertising-form-company-information-accordion"
    >
      <AdvertisingSelect
        name={Field.CompanyType}
        inputTitle={translate('fields.company_type.title')}
        data={companyTypeData}
        placeholder={translate('fields.company_type.placeholder')}
        required
      />
      <AdvertisingInputText
        name={Field.CompanyName}
        title={translate('fields.company_name.title')}
        placeholder={translate('fields.company_name.placeholder')}
        required
      />
    </FormAccordion>
  )
}

export default CompanyInformation
