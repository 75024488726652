'use client'

import { useContext } from 'react'

import { ConversationContext } from './ConversationContext'

const useConversationContext = () => {
  const context = useContext(ConversationContext)

  if (!context) {
    throw new Error('useConversationContext must be used within a ConversationProvider')
  }

  return context
}

export default useConversationContext
