'use client'

import { Cell, Image, Spacer, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'
import { MAIN_NAVIGATION_ABOUT_TAB_URL } from 'constants/routes'
import useCompanyWeb from 'hooks/useCompanyWeb'

const Advertising = () => {
  const adsTranslate = useTranslate('advertising')
  const menuTranslate = useTranslate('menu')
  const asset = useAsset('assets/icons')
  const { newsroomUrl } = useCompanyWeb()

  return (
    <div className="regular-container">
      <div className="u-desktops-only">
        <Spacer size={Spacer.Size.X2Large} />
      </div>
      <div className="row">
        <div className="grid4 u-desktops-only">
          <div className="u-ui-margin-right-medium">
            <Image src={asset('megaphone.svg')} scaling={Image.Scaling.Cover} />
          </div>
        </div>

        <div className="grid8">
          <Cell title={<Text text={adsTranslate('title')} type={Text.Type.Heading} as="h1" />}>
            <Spacer size={Spacer.Size.X2Large} />
            <Text as="span" text={adsTranslate('description')} />
          </Cell>

          <Cell>
            <Text as="span" text={adsTranslate('contact_headline')} />
            <Text as="span" width={Text.Width.Parent}>
              <a href={`mailto:${adsTranslate('contact_email')}`}>
                {adsTranslate('contact_email')}
              </a>
            </Text>
          </Cell>

          <Cell>
            <Text as="span" text={adsTranslate('learn_more')} />
            <Spacer size={Spacer.Size.Large} />
            <Text as="span" width={Text.Width.Parent}>
              <a href={MAIN_NAVIGATION_ABOUT_TAB_URL}>{menuTranslate('about_us')}</a>
            </Text>
            <Text as="span" width={Text.Width.Parent}>
              <a href={newsroomUrl}>{menuTranslate('press')}</a>
            </Text>
          </Cell>
        </div>
      </div>
    </div>
  )
}

export default Advertising
