'use client'

import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useFetch } from '@marketplace-web/shared/api-client'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { getCountries } from 'data/api'
import { transformCountryDtos } from 'data/transformers/country'

import { Field } from '../../constants'
import { FormModel } from '../../types'
import FormAccordion from '../FormAccordion'
import AdvertisingSelect from '../Inputs/AdvertisingSelect'
import AdvertisingTextarea from '../Inputs/AdvertisingTextarea'

const AdditionalInformation = () => {
  const [isExpanded, setIsExpanded] = useState(true)
  const translate = useTranslate('advertising.form.additional_information')
  const { formState } = useFormContext<FormModel>()

  const { fetch: fetchCountries, transformedData: countries } = useFetch(getCountries, response =>
    transformCountryDtos(response.countries),
  )

  useEffect(() => {
    fetchCountries('en-fr')
  }, [fetchCountries])

  const estimatedBudgetData = useMemo(
    () => [
      {
        id: '5k-10k',
        title: translate('fields.estimated_budget.options.five_to_ten'),
      },
      {
        id: '10k-30k',
        title: translate('fields.estimated_budget.options.ten_to_thirty'),
      },
      {
        id: '30k-50k',
        title: translate('fields.estimated_budget.options.thirty_to_fifty'),
      },
      {
        id: '50k-100k',
        title: translate('fields.estimated_budget.options.fifty_to_hundred'),
      },
      {
        id: '100k-500k',
        title: translate('fields.estimated_budget.options.hundred_to_five_hundred'),
      },
      {
        id: '500k+',
        title: translate('fields.estimated_budget.options.over_five_hundred'),
      },
    ],
    [translate],
  )

  const targetCountriesData = useMemo(() => {
    const mappedCountries = countries?.map(country => ({
      id: country.isoCode,
      title: country.title,
      value: country.title,
    }))

    const sortedCountries = (mappedCountries || []).sort((countryA, countryB) =>
      countryA.value.localeCompare(countryB.value),
    )

    return [
      ...sortedCountries,
      { id: 'Other', title: translate('fields.target_country.options.other'), value: 'Other' },
    ]
  }, [countries, translate])

  const dealTypeData = useMemo(
    () => [
      {
        id: 'Ad campaign',
        title: translate('fields.deal_type.options.programmatic_guaranteed_dv360'),
      },
      {
        id: 'Other partnerships',
        title: translate('fields.deal_type.options.programmatic_guaranteed_other_dsp'),
      },
    ],
    [translate],
  )

  useEffect(() => {
    if (
      formState.errors[Field.EstimatedBudget] ||
      formState.errors[Field.TargetCountry] ||
      formState.errors[Field.DealType] ||
      formState.errors[Field.Comment]
    )
      setIsExpanded(true)
  }, [formState])

  return (
    <FormAccordion
      title={translate('title')}
      isExpanded={isExpanded}
      onToggle={setIsExpanded}
      testId="advertising-form-additional-information-accordion"
    >
      <AdvertisingSelect
        name={Field.EstimatedBudget}
        inputTitle={translate('fields.estimated_budget.title')}
        data={estimatedBudgetData}
        placeholder={translate('fields.estimated_budget.placeholder')}
        required
      />
      <AdvertisingSelect
        name={Field.TargetCountry}
        inputTitle={translate('fields.target_country.title')}
        data={targetCountriesData}
        placeholder={translate('fields.target_country.placeholder')}
        required
      />
      <AdvertisingSelect
        name={Field.DealType}
        inputTitle={translate('fields.deal_type.title')}
        data={dealTypeData}
        placeholder={translate('fields.deal_type.placeholder')}
        required
      />
      <AdvertisingTextarea
        name={Field.Comment}
        title={translate('fields.comment.title')}
        placeholder={translate('fields.comment.placeholder')}
        required
      />
    </FormAccordion>
  )
}

export default AdditionalInformation
