import { CountryDto } from 'types/dtos/country'
import { CountryModel } from 'types/models/country'

export const transformCountryDto = (countryDto: CountryDto): CountryModel => ({
  id: countryDto.id,
  isoCode: countryDto.iso_code,
  title: countryDto.title,
  titleLocal: countryDto.title_local,
  postalCodeConstraints: {
    maxLength: countryDto.postal_code_constraints.max_length,
    minLength: countryDto.postal_code_constraints.min_length,
    keyboardInputType: countryDto.postal_code_constraints.keyboard_input_type,
    format: countryDto.postal_code_constraints.format,
    blacklistRegex: countryDto.postal_code_constraints.blacklist_regex,
  },
})

export const transformCountryDtos = (countryDtos: Array<CountryDto>): Array<CountryModel> =>
  countryDtos.map(transformCountryDto)
