import { Image } from '@vinted/web-ui'

import { CDN_IMAGES_URL } from '@marketplace-web/shared/ui-helpers'

type Props = {
  isMobile?: boolean
}

const mobileImageSrc = `${CDN_IMAGES_URL}/landing/advertising/hero-mobile.png`
const mobileImageSrcSet = `${CDN_IMAGES_URL}/landing/advertising/hero-mobile.png 1x, ${CDN_IMAGES_URL}/landing/advertising/hero-mobile@2x.png 1.5x, ${CDN_IMAGES_URL}/landing/advertising/hero-mobile@3x.png 2x`

const webImageSrc = `${CDN_IMAGES_URL}/landing/advertising/hero-web.png`
const webImageSrcSet = `${CDN_IMAGES_URL}/landing/advertising/hero-web.png 1x, ${CDN_IMAGES_URL}/landing/advertising/hero-web@2x.png 1.5x, ${CDN_IMAGES_URL}/landing/advertising/hero-web@3x.png 2x`

const AdvertisingHeroImage = ({ isMobile = false }: Props) =>
  isMobile ? (
    <div className="advertising__mobile-hero--image-container">
      <div className="advertising__mobile-hero--image">
        <Image
          src={mobileImageSrc}
          srcset={mobileImageSrcSet}
          alt=""
          loading="lazy"
          scaling={Image.Scaling.Contain}
        />
      </div>
    </div>
  ) : (
    <div className="advertising__hero--image-container">
      <div className="advertising__hero--image">
        <Image
          src={webImageSrc}
          srcset={webImageSrcSet}
          alt=""
          loading="lazy"
          scaling={Image.Scaling.Contain}
        />
      </div>
    </div>
  )

export default AdvertisingHeroImage
