'use client'

import { useEffect } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { viewScreenEvent } from '_libs/common/event-tracker/events'

import useTracking from '../../hooks/useTracking'

const TrackScreen = () => {
  const { track } = useTracking()
  const { screen } = useSession()

  useEffect(() => {
    track(viewScreenEvent({ screen }))
  }, [screen, track])

  return null
}

export default TrackScreen
