'use client'

import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'

import { useConversationContext } from '@marketplace-web/domain/core-conversation'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useUserAgent } from '@marketplace-web/shared/request'
import { useSession } from '@marketplace-web/shared/session'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'

import { receivedAdEvent } from '_libs/common/event-tracker/events'
import { getRoktTransactionsAttributes } from 'data/api'
import { transformRoktTransactionAttributes } from 'data/transformers/ads'
import { RoktLauncherSelection } from 'types/ads'

import { AdPage, AdPlatform, ROKT_HVF_PRICE_THRESHOLD, RoktEvent } from '../../../constants'
import AdsContext from '../../../containers/AdsProvider/AdsContext'
import { encryptEmail } from '../../../utils/email-encrypt'
import useAdLoadtimeLogging from '../useAdLoadtimeLogging'
import useRequestedAdLogging from '../useRequestedAdLogging'
import useShouldShowRokt from './useShouldShowRokt'

const RoktAdvertisement = () => {
  const selection = useRef<RoktLauncherSelection | null>(null)

  const { user } = useSession()
  const { locale } = useIntl()
  const { userCountry: countryCode } = useSystemConfiguration() || {}
  const { transaction } = useConversationContext()
  const { getAdsPlatform } = useContext(AdsContext)
  const transactionId = transaction?.id

  const isRoktScriptEnabled = useFeatureSwitch('web_ads_rokt_script')
  const isImpressionTrackingEnabled = useFeatureSwitch('web_ads_impression_tracking')

  const { setShouldShowRokt, shouldShowRokt, roktOrderPrice } = useShouldShowRokt()

  const roktLegalTransparencyTest = useAbTest('web_rokt_legal_transparency_v2')
  useTrackAbTest(roktLegalTransparencyTest, shouldShowRokt)
  let trackingCode = countryCode ? `${countryCode}_web_successful_purchase_rokt` : ''

  if (roktLegalTransparencyTest?.variant === 'A' && trackingCode)
    trackingCode += '_transparency_v2_A'
  if (roktLegalTransparencyTest?.variant === 'B' && trackingCode)
    trackingCode += '_transparency_v2_B'
  if (roktLegalTransparencyTest?.variant === 'C' && trackingCode)
    trackingCode += '_transparency_v2_C'

  const { track } = useTracking()
  const userAgent = useUserAgent()
  const isSandbox =
    typeof window !== 'undefined' &&
    (window.location.host.includes('sandbox') || window.location.host.includes('localhost'))

  const adsPlatform = useMemo(() => getAdsPlatform(userAgent), [userAgent, getAdsPlatform])

  const { onRequest: onAdLoadtimeRequest, onLoad } = useAdLoadtimeLogging({
    countryCode,
    shape: 'Rokt',
    page: AdPage.Messages,
    platform: adsPlatform,
  })
  const { onRequest: onRequestedAdRequest } = useRequestedAdLogging(trackingCode)

  const isHvfOrder = Number(roktOrderPrice) >= ROKT_HVF_PRICE_THRESHOLD

  const handleOnPlacementRequest = useCallback(() => {
    onAdLoadtimeRequest()
    onRequestedAdRequest()
  }, [onAdLoadtimeRequest, onRequestedAdRequest])

  const handleOnPlacementLoad = useCallback(() => {
    if (isImpressionTrackingEnabled && countryCode) {
      track(
        receivedAdEvent({
          placementId: trackingCode,
          isMobileWeb: getAdsPlatform(userAgent) === AdPlatform.Mobile,
          countryCode,
        }),
      )
    }

    onLoad()
  }, [
    track,
    trackingCode,
    userAgent,
    onLoad,
    countryCode,
    isImpressionTrackingEnabled,
    getAdsPlatform,
  ])

  const getRoktAttributes = useCallback(async () => {
    if (!user || !transactionId) return null

    const userEmail = user.email ? await encryptEmail(user.email) : undefined
    const userFirstName = user.real_name?.split(' ')[0]

    const attributes = {
      sandbox: isSandbox,
      placementid: trackingCode,
      customerid: user.id,
      emailsha256: userEmail,
      first_name: userFirstName,
      language: locale.toUpperCase(),
      country: user.country_iso_code.toUpperCase(),
    }

    const roktTransacitonAttributesResponse = await getRoktTransactionsAttributes({
      transactionId,
    })

    const errorsInRoktTransacitonAttributesResponse = 'errors' in roktTransacitonAttributesResponse

    if (!errorsInRoktTransacitonAttributesResponse) {
      Object.assign(
        attributes,
        roktTransacitonAttributesResponse
          ? transformRoktTransactionAttributes(roktTransacitonAttributesResponse)
          : {},
      )
    }

    return attributes
  }, [isSandbox, transactionId, trackingCode, locale, user])

  const selectRoktPlacement = useCallback(async () => {
    const attributes = await getRoktAttributes()

    if (!window.RoktLauncher || !attributes) return

    setShouldShowRokt(false)
    const launcher = await window.RoktLauncher

    handleOnPlacementRequest()

    selection.current = await launcher.selectPlacements({ attributes })
    selection.current.on(RoktEvent.PlacementReady).subscribe(handleOnPlacementLoad)
  }, [handleOnPlacementRequest, handleOnPlacementLoad, setShouldShowRokt, getRoktAttributes])

  useEffect(() => {
    if (!shouldShowRokt || isHvfOrder) return

    selectRoktPlacement()
  }, [selectRoktPlacement, shouldShowRokt, isHvfOrder])

  useEffect(() => {
    return () => {
      if (!selection.current) return

      selection.current.close()
    }
  }, [])

  if (!isRoktScriptEnabled) return null

  return <div id="rokt-placeholder" data-testid="rokt-test-placeholder" />
}

export default RoktAdvertisement
