import AdvertisingHero from './components/AdvertisingHero'
import AdvertisingLeadForm from './components/AdvertisingLeadForm'

const AdvertisingNewLeadForm = () => {
  return (
    <div className="advertising-page">
      <AdvertisingHero />
      <AdvertisingLeadForm />
    </div>
  )
}

export default AdvertisingNewLeadForm
