'use client'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import AdvertisingLeadForm from './AdvertisingNewLeadForm'
import AdvertisingOldPage from './AdvertisingOldPage'

const Advertising = () => {
  const isAdsLeadFormEnabled = useFeatureSwitch('web_ads_lead_form')

  return isAdsLeadFormEnabled ? <AdvertisingLeadForm /> : <AdvertisingOldPage />
}

export default Advertising
