export enum ListItemContentType {
  Item = 'item',
  User = 'user',
  Brand = 'brand',
  MatchingBrandSuggestion = 'matching_brand_suggestion',
  Ad = 'ad',
  PromotedCloset = 'promoted_closet',
  ListerActivationBanner = 'lister_activation_banner',
  FollowedUserBlock = 'followed_user_block',
}
