import { ReactNode } from 'react'
import { Accordion, Badge, ColorCode, Divider, Icon } from '@vinted/web-ui'

import { ChevronDown16, ChevronUp16 } from '@vinted/monochrome-icons'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

type Props = {
  children: Array<ReactNode>
} & Omit<ComponentProps<typeof Accordion>, 'content' | 'styling'>

const FormAccordion = ({ title, children, ...props }: Props) => {
  function renderBadge() {
    return (
      <div className="advertising__form-section--badge-container">
        <Badge
          content={<span className="advertising__form-section--badge-increment" />}
          theme="primary"
        />
      </div>
    )
  }

  function renderIcon(isExpanded: boolean) {
    return (
      <div className="advertising__form-section--chevron">
        <Icon name={isExpanded ? ChevronUp16 : ChevronDown16} color={ColorCode.GreyscaleLevel2} />
      </div>
    )
  }

  function renderTitle() {
    return <div className="advertising__form-section--title">{title}</div>
  }

  function renderContent() {
    return (
      <div className="advertising__form-section--content">
        <SeparatedList separator={<Divider />}>{children}</SeparatedList>
      </div>
    )
  }

  return (
    <Accordion
      styling={Accordion.Styling.TightExperimental}
      expandedPrefix={renderBadge()}
      closedPrefix={renderBadge()}
      closedSuffix={renderIcon(false)}
      expandedSuffix={renderIcon(true)}
      title={renderTitle()}
      content={renderContent()}
      {...props}
    />
  )
}

export default FormAccordion
