'use client'

import { InView } from 'react-intersection-observer'
import { useRef, type ReactNode } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'
import { impressionEvent } from '_libs/common/event-tracker/events'

import { AdShape } from '../../constants'
import Advertisement from './Advertisement'

type Props = {
  id: string | number
  position: number
  renderFallback?: () => ReactNode
  suffix?: ReactNode
}

const HomeAdvertisement = (props: Props) => {
  const { track } = useTracking()
  const isSeen = useRef(false)

  const handleVisible = (inView: boolean) => {
    if (isSeen.current) return
    if (!inView) return

    isSeen.current = true
    track(
      impressionEvent({
        id: props.id,
        position: props.position,
        contentType: ListItemContentType.Ad,
        contentSource: ContentSource.AdFeed,
      }),
    )
  }

  return (
    <InView onChange={handleVisible}>
      <Advertisement
        shape={AdShape.Inbetween}
        renderFallback={props.renderFallback}
        suffix={props.suffix}
      />
    </InView>
  )
}

export default HomeAdvertisement
