'use client'

import { useFormContext } from 'react-hook-form'
import { InputTextArea, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

import { Field, MAX_TEXTAREA_LENGTH } from '../../../constants'
import { FormModel } from '../../../types'

type Props = {
  name: Field
} & Pick<ComponentProps<typeof InputTextArea>, 'title' | 'placeholder' | 'required'>

const AdvertisingTextarea = ({ name, title, placeholder, required }: Props) => {
  const { register, watch, formState } = useFormContext<FormModel>()
  const translate = useTranslate('advertising.form.validation')

  const value = watch(name)
  const symbolsLeftCount = Math.max(MAX_TEXTAREA_LENGTH - (value?.length || 0), 0)

  return (
    <>
      <InputTextArea
        {...register(name, {
          required,
        })}
        title={title}
        placeholder={placeholder}
        styling={InputTextArea.Styling.Wide}
        uncontrolled
        maxLength={MAX_TEXTAREA_LENGTH}
        aria={{
          'aria-invalid': !!formState.errors[name],
          'aria-errormessage': `${name}-error`,
          'aria-required': required,
        }}
        validation={
          formState.errors[name] ? (
            <Text type={Text.Type.Caption} theme="warning" as="p" id={`${name}-error`}>
              {translate('required')}
            </Text>
          ) : (
            <Text type={Text.Type.Caption} theme="muted" as="p">
              {translate('symbols_left', { count: symbolsLeftCount })}
            </Text>
          )
        }
      />
      {!symbolsLeftCount && (
        <div className="advertising__form-input--textarea-a11y" aria-live="assertive">
          {translate('symbols_left_a11y', { count: MAX_TEXTAREA_LENGTH })}
        </div>
      )}
    </>
  )
}

export default AdvertisingTextarea
